import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/Users/psdev/Documents/gitdev/workspace/working/myproject/hds25-gatby4-anndream-website-2022/site/src/components/layout.js";
import LeadParagraph from '../../components/LeadParagraph';
import Image from '../../components/Image';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "getting-started-with-hds",
      "style": {
        "position": "relative"
      }
    }}>{`Getting started with HDS`}<a parentName="h1" {...{
        "href": "#getting-started-with-hds",
        "aria-label": "getting started with hds permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h1>
    <LeadParagraph mdxType="LeadParagraph">
  The Helsinki Design System is a shared point of reference for designers and developers that helps teams work and communicate more efficiently.
    </LeadParagraph>
    <p>{`The system provides working code, design resources, and guidelines that bring predictability to the product development process and help keep the City of Helsinki brand more cohesive throughout all digital services.`}</p>
    <Image size="M" src="/images/getting-started/structure-graph.svg" alt="Helsinki Design System structure graph" style={{
      "display": "block",
      "width": "100%",
      "minWidth": "600px",
      "maxWidth": "915px",
      "margin": "0 auto"
    }} mdxType="Image" />
    <p>{`The Helsinki Design System consists of several parts:`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`For designers`}</strong>{`, HDS offers Abstract libraries and a downloadable design kit. Both include design files of all components in the HDS component library. They help designers create beautiful, usable, and accessible user experiences that follow the City of Helsinki brand guidelines.`}</li>
      <li parentName="ul"><strong parentName="li">{`For developers`}</strong>{`, HDS offers component libraries (Core and React) that provide modular, accessible components for building scalable digital services efficiently.`}</li>
      <li parentName="ul"><strong parentName="li">{`For all`}</strong>{`, HDS offers visual assets like icons and logos to help keep the visual language consistent.`}</li>
      <li parentName="ul"><strong parentName="li">{`For all`}</strong>{`, HDS offers a documentation site that collects detailed information on how to use the HDS components, design tokens, and visual assets in practice, including design principles, accessibility and usage guidelines, examples, interactive demos, and API descriptions.`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      